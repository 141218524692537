export const strings = {
	it: {
		// PROFILE
		"Profile hello": "Ciao",
		"Profile redirecting": "Reindirizzamento in corso...",
		"Profile description":
			"Sei all'interno del tuo account Cosmogas. <br>Clicca uno dei bottoni qui sotto per accedere ai nostri portali: ",
		// SIGN IN
		"Sign in title": "Accedi al tuo account",
		"Sign in email label": "Email",
		"Sign in email placeholder": "Inserisci la tua mail",
		"Sign in foreward": "Avanti",
		"Sign in submit": "Accedi",
		"Sign in not registered": "Non sei ancora registrato?",
		"Sign in request registration": "Richiedi registrazione",
		"Sign in password label": "Password",
		"Sign in password placeholder": "Inserisci la tua password",
		"Sign in forgot password": "Password dimenticata?",
		// FORGOT PASSWORD
		"Forgot password title": "Password dimenticata?",
		"Forgot password description": "Inserisci la mail del tuo account Cosmogas",
		"Forgot password email label": "Email",
		"Forgot password email placeholder": "Inserisci la mail del tuo account",
		"Forgot password submit": "Reset password",
		// CONFIRM RESET PASSWORD
		"Confirm reset password code title": "Controlla la tua email",
		"Confirm reset password code description":
			"Inserisci il codice di verifica inviato su ",
		"Confirm reset password code resend": "Invia un nuovo codice",
		"Confirm reset password next step":
			"Dopo aver inserito il codice, compila i campi qui sotto per creare la tua nuova password",
		"Confirm reset password password title": "Crea password",
		"Confirm reset password password description": "Crea la tua nuova password",
		"Confirm reset password password newPassword label": "Password",
		"Confirm reset password password newPassword placeholder":
			"Inserisci la tua nuova password",
		"Confirm reset password password repeatPassword label":
			"Ripeti la password",
		"Confirm reset password password repeatPassword placeholder":
			"Inserisci nuovamente la password",
		"Confirm reset password password list minimum characters":
			"Minimo 8 caratteri",
		"Confirm reset password password list password match":
			"Le password devono corrispondere",
		"Confirm reset password password list divider":
			"Includi almeno tre dei seguenti tipi di caratteri:",
		"Confirm reset password password list uppercase": "Maiuscole",
		"Confirm reset password password list lowercase": "Minuscole",
		"Confirm reset password password list numbers": "Numeri",
		"Confirm reset password password list characters":
			"Caratteri non alfanumerici",
		"Confirm reset password password submit": "Reset password",
		// RESET PASSWORD SUCCESS
		"Reset password success title": "Password modificata!",
		"Reset password success description":
			"La tua password è stata reimpostata con successo. Clicca qui sotto per accedere",
		"Reset password success button label": "Continua",
		// GENERAL
		"Back to login": "Torna al login",
		Redirecting: "Ti stiamo riportando a: ",
		// FOOTER
		"Footer text":
			"© Cosmogas SRL | P.IVA 00810400408 | Società a Socio unico soggetta a direzione e coordinamento di Clada Srl",
		"Footer privacy link": "https://www.cosmogas.com/privacy-policy",
		"Footer cookie link": "https://www.cosmogas.com/cookie-policy",
		// AMPLIFY ERRORS
		"UserMigration failed with error User not found.": "Utente non trovato. ",
		"Incorrect username or password.": "Username o password non corretti. ",
		"Password attempts exceeded":
			"Tentativi di inserimento della password superati. ",
		"username is required to resetPassword":
			"Il nome utente è necessario per reimpostare la password. ",
		"Your passwords must match": "Le password devono corrispondere. ",
		"Invalid verification code provided, please try again.":
			"Codice di verifica fornito non valido, per favore riprovare. ",
		"Password must have at least 8 characters":
			"Le password devono avere almeno 8 caratteri. ",
		"Attempt limit exceeded, please try after some time.":
			"Limite di tentativi superato, riprova tra qualche tempo. ",
		"Password does not meet requirements":
			"La password non soddisfa i requisiti. ",
		"confirmationCode is required to confirmResetPassword":
			"Il codice di verifica è necessario per confermare la reimpostazione della password. ",
		"User does not exist.": "L'utente non esiste. ",
		"Temporary password not correct.":
			'Errore durante la fase di attivazione dell\'account. Clicca su "Torna al login" e reinserisci la tua mail e la password temporanea che hai ricevuto. ',
		"Temporary password required.": "Inserire la password temporanea. ",
		"Something went wrong. Try later.": "Qualcosa è andato storto. Riprova. ",
		"Username/client id combination not found.": "Utente non trovato. ",
		"Compila il campo richiesto.": "Compila i campi richiesti. ",
		"Cannot reset password for the user as there is no registered/verified email or phone_number":
			"Non è possibile reimpostare la password per l'utente perché non è registrato/verificato un'email o un numero di telefono. ",
		// SIGN UP
		"Sign up title": "Richiedi registrazione",
		"Sign up description":
			"Compila i campi qui sotto per richiedere il tuo account Cosmogas",
		"Sign up email label": "Email*",
		"Sign up email placeholder": "Inserisci la tua mail",
		"Sign up first name label": "Nome*",
		"Sign up first name placeholder": "Inserisci il tuo nome",
		"Sign up last name label": "Cognome*",
		"Sign up last name placeholder": "Inserisci il tuo cognome",
		"Sign up phone label": "Telefono*",
		"Sign up phone placeholder": "Inserisci il tuo numero di telefono",
		"Sign up partitaIva label": "Partita IVA*",
		"Sign up partitaIva placeholder": "Inserisci la tua P.IVA",
		"Sign up ragioneSociale label": "Ragione Sociale*",
		"Sign up ragioneSociale placeholder": "Ragione Sociale",
		"Sign up role label": "Ruolo*",
		"Sign up role placeholder": "Inserisci il tuo ruolo",
		"Sign up role option centro-assistenza": "Centro Assistenza",
		"Sign up role option installatore": "Installatore",
		"Sign up role option progettista": "Progettista",
		"Sign up role option rivenditore": "Rivenditore",
		"Sign up role option privato": "Privato",
		"Sign up address label": "Indirizzo*",
		"Sign up address placeholder": "Inserisci il tuo indirizzo",
		"Sign up province label": "Provincia*",
		"Sign up province placeholder": "Inserisci la tua provincia",
		"Sign up city label": "Città*",
		"Sign up city placeholder": "Inserisci la tua città",
		"Sign up nation label": "Nazione*",
		"Sign up nation placeholder": "Inserisci la tua nazione",
		"Sign up privacy":
			"Ho letto l'informativa sulla <a href='https://www.iubenda.com/privacy-policy/42441913' target='_blank'>privacy</a> e voglio ricevere comunicazioni da Cosmogas",
		"Sign up submit": "Richiedi registrazione",
		"Already registered": "Sei già registrato?",
		"Sign in": "Accedi",
		// SIGNUP SUCCESS
		"Sign up success title": "Richiesta inviata!",
		"Sign up success description":
			"La tua richiesta è stata inviata con successo. Attendi che il tuo account venga confermato. <br><br>Riceverai una mail quando potrai fare l’accesso",
		"Sign up success button label": "Scopri i vantaggi per i professionisti",
		"Sign up success link":
			"https://www.cosmogas.com/professionisti/vantaggi-per-i-professionisti/",
		// Account activation
		"Account activation alert": "Attenzione, non ricaricare la pagina",
		"Account activation temp password title": "Inserisci password temporanea",
		"Account activation temp password description":
			"Inserisci la password temporanea che hai ricevuto su ",
		"Account activation temp password label": "Password temporanea",
		"Account activation temp password placeholder":
			"Inserisci la password temporanea",
		"Account activation password title": "Crea nuova password",
		"Account activation password description":
			"Hai quasi finito! Per attivare completamente il tuo account, crea una nuova password. Questa è la password che dovrei usare per accedere al Portale in futuro",
		"Account activation password newPassword label": "Password",
		"Account activation password newPassword placeholder":
			"Inserisci la tua nuova password",
		"Account activation password repeatPassword label": "Ripeti la password",
		"Account activation password repeatPassword placeholder":
			"Inserisci nuovamente la password",
		"Account activation password list minimum characters": "Minimo 8 caratteri",
		"Account activation password list divider":
			"Includi almeno tre dei seguenti tipi di caratteri:",
		"Account activation password list uppercase": "Maiuscole",
		"Account activation password list lowercase": "Minuscole",
		"Account activation password list numbers": "Numeri",
		"Account activation password list characters": "Caratteri non alfanumerici",
		"Account activation password submit": "Attiva account",
		// ACCOUNT ACTIVATION SUCCESS
		"Account activation success title": "Account Attivato con Successo",
		"Account activation success description":
			"Benvenuto! Il tuo account Cosmogas è stato attivato correttamente.",
		"Account activation success description full":
			"Benvenuto! Il tuo account Cosmogas è stato attivato correttamente. Da ora puoi utilizzare le credenziali create per accedere a questi servizi:",
		"Account activation success portale label": "Portale My Cosmogas",
		"Account activation success portale link": "https://my.cosmogas.com",
		"Account activation success website label": "Sito web Cosmogas",
		"Account activation success website link": "https://cosmogas.com",
		"Account activation success cosmoclub label": "COSMOclub",
		"Account activation success cosmoclub link":
			"https://cosmoclub.cosmogas.com",
		"Account activation success acquacalda label": "Acquacalda.tech",
		"Account activation success acquacalda link":
			"https://www.acquacalda.tech/",
		"Account activation success ricambi label": "Cosmogas Ricambi",
		"Account activation success ricambi link": "https://cosmogas.ricambio.net/",
	},
	"us/ca": {
		// PROFILE
		"Profile hello": "Hello",
		"Profile redirecting": "Redirecting...",
		"Profile description":
			"You are inside your Cosmogas account. <br>Click one of the buttons below to access our portals: ",
		// SIGN IN
		"Sign in title": "Sign in to your account",
		"Sign in email label": "Email",
		"Sign in email placeholder": "Enter your email",
		"Sign in foreward": "Next",
		"Sign in submit": "Sign in",
		"Sign in not registered": "Not registered yet?",
		"Sign in request registration": "Request registration",
		"Sign in password label": "Password",
		"Sign in password placeholder": "Enter your password",
		"Sign in forgot password": "Forgot password?",
		// FORGOT PASSWORD
		"Forgot password title": "Forgot your password?",
		"Forgot password description":
			"Enter the email address associated with your Cosmogas account",
		"Forgot password email label": "Email",
		"Forgot password email placeholder": "Enter your account email",
		"Forgot password submit": "Reset password",
		// CONFIRM RESET PASSWORD
		"Confirm reset password code title": "Check your email",
		"Confirm reset password code description":
			"Enter the verification code sent to ",
		"Confirm reset password code resend": "Resend code",
		"Confirm reset password next step":
			"After entering the code, fill in the fields below to create your new password",
		"Confirm reset password password title": "Create password",
		"Confirm reset password password description": "Create your new password",
		"Confirm reset password password newPassword label": "Password",
		"Confirm reset password password newPassword placeholder":
			"Enter your new password",
		"Confirm reset password password repeatPassword label": "Repeat password",
		"Confirm reset password password repeatPassword placeholder":
			"Enter your password again",
		"Confirm reset password password list minimum characters":
			"Minimum 8 characters",
		"Confirm reset password password list password match":
			"Passwords must match",
		"Confirm reset password password list divider":
			"Include at least three of the following types of characters:",
		"Confirm reset password password list uppercase": "Uppercase",
		"Confirm reset password password list lowercase": "Lowercase",
		"Confirm reset password password list numbers": "Numbers",
		"Confirm reset password password list characters":
			"Non-alphanumeric characters",
		"Confirm reset password password submit": "Reset password",
		// RESET PASSWORD SUCCESS
		"Reset password success title": "Password changed!",
		"Reset password success description":
			"Your password has been reset successfully. Click below to sign in",
		"Reset password success button label": "Continue",
		// GENERAL
		"Back to login": "Back to login",
		Redirecting: "We are redirecting you to: ",
		// FOOTER
		"Footer text":
			"© Cosmogas SRL | P.IVA 00810400408 | Società a Socio unico soggetta a direzione e coordinamento di Clada Srl",
		"Footer privacy link": "https://www.cosmogas.com/en/privacy-policy",
		"Footer cookie link": "https://www.cosmogas.com/en/cookie-policy",
		// AMPLIFY ERRORS
		"UserMigration failed with error User not found.": "User not found. ",
		"Incorrect username or password.": "Incorrect username or password. ",
		"Password attempts exceeded": "Password entry attempts exceeded. ",
		"username is required to resetPassword":
			"Username is required to reset password. ",
		"Your passwords must match": "Your passwords must match. ",
		"Invalid verification code provided, please try again.":
			"Invalid verification code provided, please try again. ",
		"Password must have at least 8 characters":
			"Password must have at least 8 characters. ",
		"Attempt limit exceeded, please try after some time.":
			"Attempt limit exceeded, please try after some time. ",
		"Password does not meet requirements":
			"Password does not meet requirements. ",
		"confirmationCode is required to confirmResetPassword":
			"Confirmation code is required to confirm reset password. ",
		"User does not exist.": "User does not exist. ",
		"Temporary password not correct.":
			'Error during the account activation phase. Click on "Return to login" and re-enter your email and the temporary password you received. ',
		"Temporary password required.": "Temporary password required. ",
		"Something went wrong. Try later.": "Something went wrong. Try later. ",
		"Username/client id combination not found.":
			"Username/client id combination not found. ",
		"Compila il campo richiesto.": "Please fill in the required field.",
		"Cannot reset password for the user as there is no registered/verified email or phone_number":
			"Cannot reset password for the user as there is no registered/verified email or phone_number. ",
		// SIGN UP
		"Sign up title": "Request registration",
		"Sign up description":
			"Fill in the fields below to request your Cosmogas account",
		"Sign up email label": "Email*",
		"Sign up email placeholder": "Enter your email",
		"Sign up first name label": "First name*",
		"Sign up first name placeholder": "Enter your first name",
		"Sign up last name label": "Last name*",
		"Sign up last name placeholder": "Enter your last name",
		"Sign up phone label": "Phone*",
		"Sign up phone placeholder": "Enter your phone number",
		"Sign up partitaIva label": "VAT number*",
		"Sign up partitaIva placeholder": "Enter your VAT number",
		"Sign up ragioneSociale label": "Company name*",
		"Sign up ragioneSociale placeholder": "Company name",
		"Sign up role label": "Role*",
		"Sign up role placeholder": "Enter your role",
		"Sign up role option centro-assistenza": "Customer support",
		"Sign up role option installatore": "Installer",
		"Sign up role option progettista": "Designer",
		"Sign up role option rivenditore": "Reseller",
		"Sign up role option privato": "Private",
		"Sign up address label": "Address*",
		"Sign up address placeholder": "Enter your address",
		"Sign up province label": "Province*",
		"Sign up province placeholder": "Enter your province",
		"Sign up city label": "City*",
		"Sign up city placeholder": "Enter your city",
		"Sign up nation label": "Country*",
		"Sign up nation placeholder": "Enter your country",
		"Sign up privacy":
			"I have read the <a href='https://www.iubenda.com/privacy-policy/42441913' target='_blank'>privacy policy</a> and I want to receive communications from Cosmogas",
		"Sign up submit": "Request registration",
		"Already registered": "Already registered?",
		"Sign in": "Sign in",
		// SIGNUP SUCCESS
		"Sign up success title": "Request sent!",
		"Sign up success description":
			"Your request has been sent successfully. Please wait for your account to be confirmed. <br><br>You will receive an email when you can log in",
		"Sign up success button label": "Discover the advantages for professionals",
		"Sign up success link":
			"https://www.cosmogas.com/en/benefits-for-professionals/",
		// Account activation
		"Account activation alert": "Attention, do not reload the page",
		"Account activation temp password title": "Enter temporary password",
		"Account activation temp password description":
			"Enter the temporary password you received on ",
		"Account activation temp password label": "Temporary password",
		"Account activation temp password placeholder":
			"Enter the temporary password",
		"Account activation password title": "Create new password",
		"Account activation password description":
			"You're almost done! To fully activate your account, create a new password. This is the password you will use to log in to the Portal in the future",
		"Account activation password newPassword label": "Password",
		"Account activation password newPassword placeholder":
			"Enter your new password",
		"Account activation password repeatPassword label": "Repeat password",
		"Account activation password repeatPassword placeholder":
			"Enter your password again",
		"Account activation password list minimum characters":
			"Minimum 8 characters",
		"Account activation password list divider":
			"Include at least three of the following types of characters:",
		"Account activation password list uppercase": "Uppercase",
		"Account activation password list lowercase": "Lowercase",
		"Account activation password list numbers": "Numbers",
		"Account activation password list characters":
			"Non-alphanumeric characters",
		"Account activation password submit": "Activate account",
		// ACCOUNT ACTIVATION SUCCESS
		"Account activation success title": "Account Activated Successfully",
		"Account activation success description":
			"Welcome! Your Cosmogas account has been successfully activated.",
		"Account activation success description full":
			"Welcome! Your Cosmogas account has been successfully activated. From now on you can use the credentials created to access these services:",
		"Account activation success portale label": "Portal My Cosmogas",
		"Account activation success portale link": "https://my.cosmogas.com",
		"Account activation success website label": "Website Cosmogas",
		"Account activation success website link": "https://www.cosmogas.com/en/",
		"Account activation success cosmoclub label": "COSMOclub",
		"Account activation success cosmoclub link":
			"https://cosmoclub.cosmogas.com",
		"Account activation success acquacalda label": "Acquacalda.tech",
		"Account activation success acquacalda link":
			"https://www.acquacalda.tech/",
		"Account activation success ricambi label": "Cosmogas Ricambi",
		"Account activation success ricambi link": "https://cosmogas.ricambio.net/",
	},
	gb: {
		// PROFILE
		"Profile hello": "Hello",
		"Profile redirecting": "Redirecting...",
		"Profile description":
			"You are inside your Cosmogas account. <br>Click one of the buttons below to access our portals:",
		// SIGN IN
		"Sign in title": "Sign in to your account",
		"Sign in email label": "Email",
		"Sign in email placeholder": "Enter your email",
		"Sign in foreward": "Next",
		"Sign in submit": "Sign in",
		"Sign in not registered": "Not registered yet?",
		"Sign in request registration": "Request registration",
		"Sign in password label": "Password",
		"Sign in password placeholder": "Enter your password",
		"Sign in forgot password": "Forgot password?",
		// FORGOT PASSWORD
		"Forgot password title": "Forgot your password?",
		"Forgot password description":
			"Enter the email address associated with your Cosmogas account",
		"Forgot password email label": "Email",
		"Forgot password email placeholder": "Enter your account email",
		"Forgot password submit": "Reset password",
		// CONFIRM RESET PASSWORD
		"Confirm reset password code title": "Check your email",
		"Confirm reset password code description":
			"Enter the verification code sent to ",
		"Confirm reset password code resend": "Resend code",
		"Confirm reset password next step":
			"After entering the code, fill in the fields below to create your new password",
		"Confirm reset password password title": "Create password",
		"Confirm reset password password description": "Create your new password",
		"Confirm reset password password newPassword label": "Password",
		"Confirm reset password password newPassword placeholder":
			"Enter your new password",
		"Confirm reset password password repeatPassword label": "Repeat password",
		"Confirm reset password password repeatPassword placeholder":
			"Enter your password again",
		"Confirm reset password password list minimum characters":
			"Minimum 8 characters",
		"Confirm reset password password list password match":
			"Password must match",
		"Confirm reset password password list divider":
			"Include at least three of the following types of characters:",
		"Confirm reset password password list uppercase": "Uppercase",
		"Confirm reset password password list lowercase": "Lowercase",
		"Confirm reset password password list numbers": "Numbers",
		"Confirm reset password password list characters":
			"Non-alphanumeric characters",
		"Confirm reset password password submit": "Reset password",
		// RESET PASSWORD SUCCESS
		"Reset password success title": "Password changed!",
		"Reset password success description":
			"Your password has been reset successfully. Click below to sign in",
		"Reset password success button label": "Continue",
		// GENERAL
		"Back to login": "Back to login",
		Redirecting: "We are redirecting you to: ",
		// FOOTER
		"Footer text":
			"© Cosmogas SRL | P.IVA 00810400408 | Società a Socio unico soggetta a direzione e coordinamento di Clada Srl",
		"Footer privacy link": "https://www.cosmogas.com/en-uk/privacy-policy",
		"Footer cookie link": "https://www.cosmogas.com/en-uk/cookie-policy",
		// AMPLIFY ERRORS
		"UserMigration failed with error User not found.": "User not found. ",
		"Incorrect username or password.": "Incorrect username or password. ",
		"Password attempts exceeded": "Password entry attempts exceeded. ",
		"username is required to resetPassword":
			"Username is required to reset password. ",
		"Your passwords must match": "Your passwords must match. ",
		"Invalid verification code provided, please try again.":
			"Invalid verification code provided, please try again. ",
		"Password must have at least 8 characters":
			"Password must have at least 8 characters. ",
		"Attempt limit exceeded, please try after some time.":
			"Attempt limit exceeded, please try after some time. ",
		"Password does not meet requirements":
			"Password does not meet requirements. ",
		"confirmationCode is required to confirmResetPassword":
			"Confirmation code is required to confirm reset password. ",
		"User does not exist.": "User does not exist. ",
		"Temporary password not correct.":
			'Error during the account activation phase. Click on "Return to login" and re-enter your email and the temporary password you received. ',
		"Temporary password required.": "Temporary password required. ",
		"Something went wrong. Try later.": "Something went wrong. Try later. ",
		"Username/client id combination not found.":
			"Username/client id combination not found. ",
		"Compila il campo richiesto.": "Please fill in the required field.",
		"Cannot reset password for the user as there is no registered/verified email or phone_number":
			"Cannot reset password for the user as there is no registered/verified email or phone_number. ",
		// SIGN UP
		"Sign up title": "Request registration",
		"Sign up description":
			"Fill in the fields below to request your Cosmogas account",
		"Sign up email label": "Email*",
		"Sign up email placeholder": "Enter your email",
		"Sign up first name label": "First name*",
		"Sign up first name placeholder": "Enter your first name",
		"Sign up last name label": "Last name*",
		"Sign up last name placeholder": "Enter your last name",
		"Sign up phone label": "Phone*",
		"Sign up phone placeholder": "Enter your phone number",
		"Sign up partitaIva label": "VAT number*",
		"Sign up partitaIva placeholder": "Enter your VAT number",
		"Sign up ragioneSociale label": "Company name*",
		"Sign up ragioneSociale placeholder": "Company name",
		"Sign up role label": "Role*",
		"Sign up role placeholder": "Enter your role",
		"Sign up role option centro-assistenza": "Customer support",
		"Sign up role option installatore": "Installer",
		"Sign up role option progettista": "Designer",
		"Sign up role option rivenditore": "Reseller",
		"Sign up role option privato": "Private",
		"Sign up address label": "Address*",
		"Sign up address placeholder": "Enter your address",
		"Sign up province label": "Province*",
		"Sign up province placeholder": "Enter your province",
		"Sign up city label": "City*",
		"Sign up city placeholder": "Enter your city",
		"Sign up nation label": "Country*",
		"Sign up nation placeholder": "Enter your country",
		"Sign up privacy":
			"I have read the <a href='https://www.iubenda.com/privacy-policy/42441913' target='_blank'>privacy policy</a> and I want to receive communications from Cosmogas",
		"Sign up submit": "Request registration",
		"Already registered": "Already registered?",
		"Sign in": "Sign in",
		// SIGNUP SUCCESS
		"Sign up success title": "Request sent!",
		"Sign up success description":
			"Your request has been sent successfully. Please wait for your account to be confirmed. <br><br>You will receive an email when you can log in",
		"Sign up success button label": "Discover the advantages for professionals",
		"Sign up success link":
			"https://www.cosmogas.com/en-uk/professionals/benefits-for-professionals/",
		// Account activation
		"Account activation alert": "Attention, do not reload the page",
		"Account activation temp password title": "Enter temporary password",
		"Account activation temp password description":
			"Enter the temporary password you received on ",
		"Account activation temp password label": "Temporary password",
		"Account activation temp password placeholder":
			"Enter the temporary password",
		"Account activation password title": "Create new password",
		"Account activation password description":
			"You're almost done! To fully activate your account, create a new password. This is the password you will use to log in to the Portal in the future",
		"Account activation password newPassword label": "Password",
		"Account activation password newPassword placeholder":
			"Enter your new password",
		"Account activation password repeatPassword label": "Repeat password",
		"Account activation password repeatPassword placeholder":
			"Enter your password again",
		"Account activation password list minimum characters":
			"Minimum 8 characters",
		"Account activation password list divider":
			"Include at least three of the following types of characters:",
		"Account activation password list uppercase": "Uppercase",
		"Account activation password list lowercase": "Lowercase",
		"Account activation password list numbers": "Numbers",
		"Account activation password list characters":
			"Non-alphanumeric characters",
		"Account activation password submit": "Activate account",
		// ACCOUNT ACTIVATION SUCCESS
		"Account activation success title": "Account Activated Successfully",
		"Account activation success description":
			"Welcome! Your Cosmogas account has been successfully activated.",
		"Account activation success description full":
			"Welcome! Your Cosmogas account has been successfully activated. From now on you can use the credentials created to access these services:",
		"Account activation success portale label": "Portal My Cosmogas",
		"Account activation success portale link": "https://my.cosmogas.com",
		"Account activation success website label": "Website Cosmogas",
		"Account activation success website link": "https://www.cosmogas.com/en-uk",
		"Account activation success cosmoclub label": "COSMOclub",
		"Account activation success cosmoclub link":
			"https://cosmoclub.cosmogas.com",
		"Account activation success acquacalda label": "Acquacalda.tech",
		"Account activation success acquacalda link":
			"https://www.acquacalda.tech/",
		"Account activation success ricambi label": "Cosmogas Ricambi",
		"Account activation success ricambi link": "https://cosmogas.ricambio.net/",
	},
	fr: {
		// PROFILE
		"Profile hello": "Bonjour",
		"Profile redirecting": "Redirection...",
		"Profile description":
			"Vous êtes dans votre compte Cosmogas.<br>Cliquez sur l'un des boutons ci-dessous pour accéder à nos portails: ",
		// SIGN IN
		"Sign in title": "Connectez-vous à votre compte",
		"Sign in email label": "Email",
		"Sign in email placeholder": "Entrez votre email",
		"Sign in foreward": "Suivant",
		"Sign in submit": "Connexion",
		"Sign in not registered": "Pas encore inscrit ?",
		"Sign in request registration": "Demander une inscription",
		"Sign in password label": "Mot de passe",
		"Sign in password placeholder": "Entrez votre mot de passe",
		"Sign in forgot password": "Mot de passe oublié ?",
		// FORGOT PASSWORD
		"Forgot password title": "Mot de passe oublié ?",
		"Forgot password description": "Entrez l'email de votre compte Cosmogas",
		"Forgot password email label": "Email",
		"Forgot password email placeholder": "Entrez l'email de votre compte",
		"Forgot password submit": "Réinitialiser le mot de passe",
		// CONFIRM RESET PASSWORD
		"Confirm reset password code title": "Vérifiez votre email",
		"Confirm reset password code description":
			"Entrez le code de vérification envoyé à ",
		"Confirm reset password code resend": "Envoyer un nouveau code",
		"Confirm reset password next step":
			"Après avoir entré le code, remplissez les champs ci-dessous pour créer votre nouveau mot de passe",
		"Confirm reset password password title": "Créer un mot de passe",
		"Confirm reset password password description":
			"Créez votre nouveau mot de passe",
		"Confirm reset password password newPassword label": "Mot de passe",
		"Confirm reset password password newPassword placeholder":
			"Entrez votre nouveau mot de passe",
		"Confirm reset password password repeatPassword label":
			"Répétez le mot de passe",
		"Confirm reset password password repeatPassword placeholder":
			"Entrez à nouveau le mot de passe",
		"Confirm reset password password list minimum characters":
			"Minimum 8 caractères",
		"Confirm reset password password list password match":
			"Les mots de passe doivent correspondre",
		"Confirm reset password password list divider":
			"Incluez au moins trois des types de caractères suivants :",
		"Confirm reset password password list uppercase": "Majuscules",
		"Confirm reset password password list lowercase": "Minuscules",
		"Confirm reset password password list numbers": "Chiffres",
		"Confirm reset password password list characters":
			"Caractères non alphanumériques",
		"Confirm reset password password submit": "Réinitialiser le mot de passe",
		// RESET PASSWORD SUCCESS
		"Reset password success title": "Mot de passe modifié !",
		"Reset password success description":
			"Votre mot de passe a été réinitialisé avec succès. Cliquez ci-dessous pour vous connecter",
		"Reset password success button label": "Continuer",
		// GENERAL
		"Back to login": "Retour à la connexion",
		Redirecting: "Nous vous redirigeons vers : ",
		// FOOTER
		"Footer text":
			"© Cosmogas SRL | N° TVA 00810400408 | Société à associé unique sous la direction et la coordination de Clada Srl",
		"Footer privacy link": "https://www.cosmogas.com/fr/privacy-policy",
		"Footer cookie link": "https://www.cosmogas.com/fr/cookie-policy",
		// AMPLIFY ERRORS
		"UserMigration failed with error User not found.":
			"Utilisateur non trouvé. ",
		"Incorrect username or password.":
			"Nom d'utilisateur ou mot de passe incorrects. ",
		"Password attempts exceeded":
			"Nombre de tentatives de mot de passe dépassé. ",
		"username is required to resetPassword":
			"Le nom d'utilisateur est requis pour réinitialiser le mot de passe. ",
		"Your passwords must match": "Les mots de passe doivent correspondre. ",
		"Invalid verification code provided, please try again.":
			"Code de vérification non valide, veuillez réessayer. ",
		"Password must have at least 8 characters":
			"Le mot de passe doit comporter au moins 8 caractères. ",
		"Attempt limit exceeded, please try after some time.":
			"Limite de tentatives dépassée, réessayez plus tard. ",
		"Password does not meet requirements":
			"Le mot de passe ne satisfait pas aux exigences. ",
		"confirmationCode is required to confirmResetPassword":
			"Le code de vérification est requis pour confirmer la réinitialisation du mot de passe. ",
		"User does not exist.": "L'utilisateur n'existe pas. ",
		"Temporary password not correct.":
			'Erreur lors de la phase d\'activation du compte. Cliquez sur "Retour à la connexion" et ressaisissez votre email et le mot de passe temporaire que vous avez reçu. ',
		"Temporary password required.": "Le mot de passe temporaire est requis. ",
		"Something went wrong. Try later.":
			"Quelque chose s'est mal passé. Réessayez plus tard. ",
		"Username/client id combination not found.":
			"Combinaison nom d'utilisateur/id client non trouvée. ",
		"Compila il campo richiesto.": "Remplissez le champ requis. ",
		"Cannot reset password for the user as there is no registered/verified email or phone_number":
			"Impossible de réinitialiser le mot de passe pour l'utilisateur car il n'a pas d'email ou numéro de numéro de portable. ",
		// SIGN UP
		"Sign up title": "Demander une inscription",
		"Sign up description":
			"Remplissez les champs ci-dessous pour demander votre compte Cosmogas",
		"Sign up email label": "Email*",
		"Sign up email placeholder": "Entrez votre email",
		"Sign up first name label": "Prénom*",
		"Sign up first name placeholder": "Entrez votre prénom",
		"Sign up last name label": "Nom*",
		"Sign up last name placeholder": "Entrez votre nom",
		"Sign up phone label": "Téléphone*",
		"Sign up phone placeholder": "Entrez votre numéro de téléphone",
		"Sign up partitaIva label": "N° TVA*",
		"Sign up partitaIva placeholder": "Entrez votre N° TVA",
		"Sign up ragioneSociale label": "Raison sociale*",
		"Sign up ragioneSociale placeholder": "Raison sociale",
		"Sign up role label": "Rôle*",
		"Sign up role placeholder": "Entrez votre rôle",
		"Sign up role option centro-assistenza": "Centre d'assistance",
		"Sign up role option installatore": "Installateur",
		"Sign up role option progettista": "Concepteur",
		"Sign up role option rivenditore": "Revendeur",
		"Sign up role option privato": "Particulier",
		"Sign up address label": "Adresse*",
		"Sign up address placeholder": "Entrez votre adresse",
		"Sign up province label": "Province*",
		"Sign up province placeholder": "Entrez votre province",
		"Sign up city label": "Ville*",
		"Sign up city placeholder": "Entrez votre ville",
		"Sign up nation label": "Pays*",
		"Sign up nation placeholder": "Entrez votre pays",
		"Sign up privacy":
			"J'ai lu la politique de <a href='https://www.iubenda.com/privacy-policy/42441913' target='_blank'>confidentialité</a> et je souhaite recevoir des communications de Cosmogas",
		"Sign up submit": "Demander une inscription",
		"Already registered": "Déjà inscrit ?",
		"Sign in": "Connexion",
		// SIGNUP SUCCESS
		"Sign up success title": "Demande envoyée !",
		"Sign up success description":
			"Votre demande a été envoyée avec succès. Attendez la confirmation de votre compte. <br><br>Vous recevrez un email lorsque vous pourrez vous connecter",
		"Sign up success button label":
			"Découvrez les avantages pour les professionnels",
		"Sign up success link":
			"https://www.cosmogas.com/fr/professionnels/avantages-pour-les-professionnels/",
		// Account activation
		"Account activation alert": "Attention, ne rechargez pas la page",
		"Account activation temp password title":
			"Entrez le mot de passe temporaire",
		"Account activation temp password description":
			"Entrez le mot de passe temporaire que vous avez reçu à ",
		"Account activation temp password label": "Mot de passe temporaire",
		"Account activation temp password placeholder":
			"Entrez le mot de passe temporaire",
		"Account activation password title": "Créer un nouveau mot de passe",
		"Account activation password description":
			"Vous y êtes presque ! Pour activer complètement votre compte, créez un nouveau mot de passe. C'est le mot de passe que vous utiliserez pour vous connecter au Portail à l'avenir",
		"Account activation password newPassword label": "Mot de passe",
		"Account activation password newPassword placeholder":
			"Entrez votre nouveau mot de passe",
		"Account activation password repeatPassword label":
			"Répétez le mot de passe",
		"Account activation password repeatPassword placeholder":
			"Entrez à nouveau le mot de passe",
		"Account activation password list minimum characters":
			"Minimum 8 caractères",
		"Account activation password list divider":
			"Incluez au moins trois des types de caractères suivants :",
		"Account activation password list uppercase": "Majuscules",
		"Account activation password list lowercase": "Minuscules",
		"Account activation password list numbers": "Chiffres",
		"Account activation password list characters":
			"Caractères non alphanumériques",
		"Account activation password submit": "Activer le compte",
		// ACCOUNT ACTIVATION SUCCESS
		"Account activation success title": "Compte activé avec succès",
		"Account activation success description":
			"Accueillir! Votre compte Cosmogas a été activé avec succès.",
		"Account activation success description full":
			"Accueillir! Votre compte Cosmogas a été activé avec succès. Vous pouvez désormais utiliser les identifiants créés pour accéder à ces services:",
		"Account activation success portale label": "Portail My Cosmogas",
		"Account activation success portale link": "https://my.cosmogas.com",
		"Account activation success website label": "Site web Cosmogas",
		"Account activation success website link": "https://www.cosmogas.com/fr/",
		"Account activation success cosmoclub label": "COSMOclub",
		"Account activation success cosmoclub link":
			"https://cosmoclub.cosmogas.com",
		"Account activation success acquacalda label": "Acquacalda.tech",
		"Account activation success acquacalda link":
			"https://www.acquacalda.tech/",
		"Account activation success ricambi label": "Cosmogas Ricambi",
		"Account activation success ricambi link": "https://cosmogas.ricambio.net/",
	},
	de: {
		// PROFILE
		"Profile hello": "Hallo",
		"Profile redirecting": "Weiterleitung...",
		"Profile description":
			"Sie befinden sich in Ihrem Cosmogas-Konto. <br>Klicken Sie auf eine der Schaltflächen unten, um auf unsere Portale zuzugreifen:",
		// SIGN IN
		"Sign in title": "Melden Sie sich bei Ihrem Konto an",
		"Sign in email label": "E-Mail",
		"Sign in email placeholder": "Geben Sie Ihre E-Mail ein",
		"Sign in foreward": "Weiter",
		"Sign in submit": "Anmelden",
		"Sign in not registered": "Noch nicht registriert?",
		"Sign in request registration": "Registrierung anfordern",
		"Sign in password label": "Passwort",
		"Sign in password placeholder": "Geben Sie Ihr Passwort ein",
		"Sign in forgot password": "Passwort vergessen?",
		"Forgot password title": "Passwort vergessen?",
		"Forgot password description":
			"Geben Sie die E-Mail-Adresse Ihres Cosmogas-Kontos ein",
		"Forgot password email label": "E-Mail",
		"Forgot password email placeholder":
			"Geben Sie die E-Mail-Adresse Ihres Kontos ein",
		"Forgot password submit": "Passwort zurücksetzen",
		"Confirm reset password code title": "Überprüfen Sie Ihre E-Mail",
		"Confirm reset password code description":
			"Geben Sie den Bestätigungscode ein, der gesendet wurde an ",
		"Confirm reset password code resend": "Neuen Code senden",
		"Confirm reset password next step":
			"Nachdem Sie den Code eingegeben haben, füllen Sie die Felder unten aus, um Ihr neues Passwort zu erstellen",
		"Confirm reset password password title": "Passwort erstellen",
		"Confirm reset password password description":
			"Erstellen Sie Ihr neues Passwort",
		"Confirm reset password password newPassword label": "Passwort",
		"Confirm reset password password newPassword placeholder":
			"Geben Sie Ihr neues Passwort ein",
		"Confirm reset password password repeatPassword label":
			"Passwort wiederholen",
		"Confirm reset password password repeatPassword placeholder":
			"Geben Sie das Passwort erneut ein",
		"Confirm reset password password list minimum characters":
			"Mindestens 8 Zeichen",
		"Confirm reset password password list password match":
			"Passwort muss identisch sein",
		"Confirm reset password password list divider":
			"Schließen Sie mindestens drei der folgenden Zeichentypen ein:",
		"Confirm reset password password list uppercase": "Großbuchstaben",
		"Confirm reset password password list lowercase": "Kleinbuchstaben",
		"Confirm reset password password list numbers": "Zahlen",
		"Confirm reset password password list characters": "Sonderzeichen",
		"Confirm reset password password submit": "Passwort zurücksetzen",
		"Reset password success title": "Passwort geändert!",
		"Reset password success description":
			"Ihr Passwort wurde erfolgreich zurückgesetzt. Klicken Sie unten, um sich anzumelden",
		"Reset password success button label": "Fortsetzen",
		"Back to login": "Zurück zum Login",
		Redirecting: "Sie werden weitergeleitet zu: ",
		"Footer text":
			"© Cosmogas SRL | USt-IdNr. 00810400408 | Einpersonengesellschaft unter der Leitung und Koordination von Clada Srl",
		"Footer privacy link": "https://www.cosmogas.com/de/privacy-policy",
		"Footer cookie link": "https://www.cosmogas.com/de/cookie-policy",
		"UserMigration failed with error User not found.":
			"Benutzer nicht gefunden.",
		"Incorrect username or password.": "Falscher Benutzername oder Passwort.",
		"Password attempts exceeded": "Anzahl der Passwortversuche überschritten.",
		"username is required to resetPassword":
			"Benutzername ist erforderlich, um das Passwort zurückzusetzen.",
		"Your passwords must match": "Die Passwörter müssen übereinstimmen.",
		"Invalid verification code provided, please try again.":
			"Ungültiger Bestätigungscode, bitte erneut versuchen.",
		"Password must have at least 8 characters":
			"Das Passwort muss mindestens 8 Zeichen lang sein.",
		"Attempt limit exceeded, please try after some time.":
			"Versuchslimit überschritten, bitte später erneut versuchen.",
		"Password does not meet requirements":
			"Das Passwort erfüllt nicht die Anforderungen.",
		"confirmationCode is required to confirmResetPassword":
			"Der Bestätigungscode ist erforderlich, um das Passwort zurückzusetzen.",
		"User does not exist.": "Benutzer existiert nicht.",
		"Temporary password not correct.":
			"Fehler während der Kontoaktivierungsphase. Klicken Sie auf „Zurück zur Anmeldung“ und geben Sie Ihre E-Mail-Adresse und das temporäre Passwort, das Sie erhalten haben, erneut ein. ",
		"Temporary password required.": "Temporäres Passwort erforderlich.",
		"Something went wrong. Try later.":
			"Etwas ist schiefgelaufen. Versuchen Sie es später erneut.",
		"Username/client id combination not found.":
			"Benutzername/Kunden-ID-Kombination nicht gefunden.",
		"Compila il campo richiesto.":
			"Bitte füllen Sie das erforderliche Feld aus.",
		"Cannot reset password for the user as there is no registered/verified email or phone_number":
			"Das Passwort für den Benutzer kann nicht zurückgesetzt werden, da keine registrierte/verifizierte E-Mail-Adresse oder Telefonnummer gefunden wurde. ",
		"Sign up title": "Registrierung anfordern",
		"Sign up description":
			"Füllen Sie die folgenden Felder aus, um Ihr Cosmogas-Konto anzufordern",
		"Sign up email label": "E-Mail*",
		"Sign up email placeholder": "Geben Sie Ihre E-Mail-Adresse ein",
		"Sign up first name label": "Vorname*",
		"Sign up first name placeholder": "Geben Sie Ihren Vornamen ein",
		"Sign up last name label": "Nachname*",
		"Sign up last name placeholder": "Geben Sie Ihren Nachnamen ein",
		"Sign up phone label": "Telefon*",
		"Sign up phone placeholder": "Geben Sie Ihre Telefonnummer ein",
		"Sign up partitaIva label": "USt-IdNr.*",
		"Sign up partitaIva placeholder": "Geben Sie Ihre USt-IdNr. ein",
		"Sign up ragioneSociale label": "Firmenname*",
		"Sign up ragioneSociale placeholder": "Firmenname",
		"Sign up role label": "Rolle*",
		"Sign up role placeholder": "Geben Sie Ihre Rolle ein",
		"Sign up role option centro-assistenza": "Kundendienstzentrum",
		"Sign up role option installatore": "Installateur",
		"Sign up role option progettista": "Planer",
		"Sign up role option rivenditore": "Händler",
		"Sign up role option privato": "Privatperson",
		"Sign up address label": "Adresse*",
		"Sign up address placeholder": "Geben Sie Ihre Adresse ein",
		"Sign up province label": "Provinz*",
		"Sign up province placeholder": "Geben Sie Ihre Provinz ein",
		"Sign up city label": "Stadt*",
		"Sign up city placeholder": "Geben Sie Ihre Stadt ein",
		"Sign up nation label": "Land*",
		"Sign up nation placeholder": "Geben Sie Ihr Land ein",
		"Sign up privacy":
			"Ich habe die <a href='https://www.iubenda.com/privacy-policy/42441913' target='_blank'>Datenschutzerklärung</a> gelesen und möchte Mitteilungen von Cosmogas erhalten",
		"Sign up submit": "Registrierung anfordern",
		"Already registered": "Bereits registriert?",
		"Sign in": "Anmelden",
		"Sign up success title": "Anfrage gesendet!",
		"Sign up success description":
			"Ihre Anfrage wurde erfolgreich gesendet. Bitte warten Sie, bis Ihr Konto bestätigt wird. <br><br>Sie erhalten eine E-Mail, wenn Sie sich anmelden können",
		"Sign up success button label": "Entdecken Sie die Vorteile für Fachleute",
		"Sign up success link":
			"https://www.cosmogas.com/en/benefits-for-professionals/",
		"Account activation alert": "Achtung, laden Sie die Seite nicht neu",
		"Account activation temp password title": "Temporäres Passwort eingeben",
		"Account activation temp password description":
			"Geben Sie das temporäre Passwort ein, das Sie erhalten haben an ",
		"Account activation temp password label": "Temporäres Passwort",
		"Account activation temp password placeholder":
			"Geben Sie das temporäre Passwort ein",
		"Account activation password title": "Neues Passwort erstellen",
		"Account activation password description":
			"Sie sind fast fertig! Um Ihr Konto vollständig zu aktivieren, erstellen Sie ein neues Passwort. Dies ist das Passwort, das Sie zukünftig für den Zugang zum Portal verwenden werden",
		"Account activation password newPassword label": "Passwort",
		"Account activation password newPassword placeholder":
			"Geben Sie Ihr neues Passwort ein",
		"Account activation password repeatPassword label": "Passwort wiederholen",
		"Account activation password repeatPassword placeholder":
			"Geben Sie das Passwort erneut ein",
		"Account activation password list minimum characters":
			"Mindestens 8 Zeichen",
		"Account activation password list divider":
			"Schließen Sie mindestens drei der folgenden Zeichentypen ein:",
		"Account activation password list uppercase": "Großbuchstaben",
		"Account activation password list lowercase": "Kleinbuchstaben",
		"Account activation password list numbers": "Zahlen",
		"Account activation password list characters": "Sonderzeichen",
		"Account activation password submit": "Konto aktivieren",
		// ACCOUNT ACTIVATION SUCCESS
		"Account activation success title": "Konto erfolgreich aktiviert",
		"Account activation success description":
			"Willkommen! Ihr Cosmogas-Konto wurde erfolgreich aktiviert.",
		"Account activation success description full":
			"Willkommen! Ihr Cosmogas-Konto wurde erfolgreich aktiviert. Ab sofort können Sie mit den erstellten Zugangsdaten auf diese Dienste zugreifen:",
		"Account activation success portale label": "Portal My Cosmogas",
		"Account activation success portale link": "https://my.cosmogas.com",
		"Account activation success website label": "Webseite Cosmogas",
		"Account activation success website link": "https://www.cosmogas.com/de/",
		"Account activation success cosmoclub label": "COSMOclub",
		"Account activation success cosmoclub link":
			"https://cosmoclub.cosmogas.com",
		"Account activation success acquacalda label": "Acquacalda.tech",
		"Account activation success acquacalda link":
			"https://www.acquacalda.tech/",
		"Account activation success ricambi label": "Cosmogas Ricambi",
		"Account activation success ricambi link": "https://cosmogas.ricambio.net/",
	},
	hu: {
		// PRFOILE
		"Profile hello": "Hello",
		"Profile redirecting": "Ugrás...",
		"Profile description":
			"Benne vagy a Cosmogas fiókodban. <br>Kattintson az alábbi gombok egyikére a portálunk eléréséhez:",
		// SIGN IN
		"Sign in title": "Jelentkezzen be fiókjába",
		"Sign in email label": "E-mail",
		"Sign in email placeholder": "Írja be e-mail-címét",
		"Sign in foreward": "Tovább",
		"Sign in submit": "Bejelentkezés",
		"Sign in not registered": "Még nem regisztrált?",
		"Sign in request registration": "Regisztráció igénylése",
		"Sign in password label": "Jelszó",
		"Sign in password placeholder": "Írja be jelszavát",
		"Sign in forgot password": "Elfelejtette jelszavát?",
		"Forgot password title": "Elfelejtett jelszó?",
		"Forgot password description": "Adja meg Cosmogas-fiókjának e-mail-címét",
		"Forgot password email label": "E-mail",
		"Forgot password email placeholder": "Adja meg fiókjának e-mail-címét",
		"Forgot password submit": "Jelszó visszaállítása",
		"Confirm reset password code title": "Ellenőrizze e-mailjét",
		"Confirm reset password code description":
			"Adja meg az ellenőrző kódot, amelyet elküldtünk ",
		"Confirm reset password code resend": "Új kód küldése",
		"Confirm reset password next step":
			"A kód megadása után töltse ki az alábbi mezőket új jelszó létrehozásához",
		"Confirm reset password password title": "Jelszó létrehozása",
		"Confirm reset password password description": "Hozza létre új jelszavát",
		"Confirm reset password password newPassword label": "Jelszó",
		"Confirm reset password password newPassword placeholder":
			"Adja meg új jelszavát",
		"Confirm reset password password repeatPassword label": "Jelszó ismétlése",
		"Confirm reset password password repeatPassword placeholder":
			"Írja be újra a jelszót",
		"Confirm reset password password list minimum characters":
			"Legalább 8 karakter",
		"Confirm reset password password list password match":
			"A jelszavaknak egyeznie kell",
		"Confirm reset password password list divider":
			"Tartalmazzon legalább három karaktertípus közül:",
		"Confirm reset password password list uppercase": "Nagybetűk",
		"Confirm reset password password list lowercase": "Kisbetűk",
		"Confirm reset password password list numbers": "Számok",
		"Confirm reset password password list characters":
			"Nem alfanumerikus karakterek",
		"Confirm reset password password submit": "Jelszó visszaállítása",
		"Reset password success title": "Jelszó megváltoztatva!",
		"Reset password success description":
			"A jelszó sikeresen visszaállítva. Kattintson az alábbi gombra a bejelentkezéshez",
		"Reset password success button label": "Folytatás",
		"Back to login": "Vissza a bejelentkezéshez",
		Redirecting: "Átirányítjuk ide: ",
		"Footer text":
			"© Cosmogas SRL | Adószám 00810400408 | Egyetlen taggal rendelkező társaság, amelyet a Clada Srl irányít és koordinál",
		"Footer privacy link": "https://www.cosmogas.com/en/privacy-policy",
		"Footer cookie link": "https://www.cosmogas.com/en/cookie-policy",
		"UserMigration failed with error User not found.":
			"Felhasználó nem található.",
		"Incorrect username or password.": "Hibás felhasználónév vagy jelszó.",
		"Password attempts exceeded": "A jelszóbevitelek száma meghaladva.",
		"username is required to resetPassword":
			"A felhasználónév szükséges a jelszó visszaállításához.",
		"Your passwords must match": "A jelszavaknak egyezniük kell.",
		"Invalid verification code provided, please try again.":
			"Érvénytelen ellenőrzőkód, próbálja újra.",
		"Password must have at least 8 characters":
			"A jelszónak legalább 8 karakter hosszúnak kell lennie.",
		"Attempt limit exceeded, please try after some time.":
			"A próbálkozási limit túllépve, próbálja újra később.",
		"Password does not meet requirements":
			"A jelszó nem felel meg a követelményeknek.",
		"confirmationCode is required to confirmResetPassword":
			"Ellenőrzőkód szükséges a jelszó visszaállításának megerősítéséhez.",
		"User does not exist.": "A felhasználó nem létezik.",
		"Temporary password not correct.":
			'Hiba a fiók aktiválási szakaszában. Kattintson a "Visszatérés a bejelentkezéshez" lehetőségre, és adja meg újra az e-mail címét és a kapott ideiglenes jelszavát.',
		"Temporary password required.": "Ideiglenes jelszó szükséges.",
		"Something went wrong. Try later.":
			"Valami hiba történt. Próbálja újra később.",
		"Username/client id combination not found.":
			"Felhasználónév/kliens ID kombináció nem található.",
		"Compila il campo richiesto.": "Töltse ki a kötelező mezőket.",
		"Cannot reset password for the user as there is no registered/verified email or phone_number":
			"A jelszó visszaállítása nem sikerúlt, mert a felhasználó nem regisztrálta, vagy nem kapott e-mailet vagy telefonszámot. ",
		"Sign up title": "Regisztráció igénylése",
		"Sign up description":
			"Töltse ki az alábbi mezőket, hogy igényelje Cosmogas-fiókját",
		"Sign up email label": "E-mail*",
		"Sign up email placeholder": "Írja be e-mail-címét",
		"Sign up first name label": "Keresztnév*",
		"Sign up first name placeholder": "Írja be keresztnevét",
		"Sign up last name label": "Vezetéknév*",
		"Sign up last name placeholder": "Írja be vezetéknevét",
		"Sign up phone label": "Telefon*",
		"Sign up phone placeholder": "Írja be telefonszámát",
		"Sign up partitaIva label": "Adószám*",
		"Sign up partitaIva placeholder": "Írja be adószámát",
		"Sign up ragioneSociale label": "Cégnév*",
		"Sign up ragioneSociale placeholder": "Cégnév",
		"Sign up role label": "Szerepkör*",
		"Sign up role placeholder": "Írja be szerepkörét",
		"Sign up role option centro-assistenza": "Szervizközpont",
		"Sign up role option installatore": "Telepítő",
		"Sign up role option progettista": "Tervező",
		"Sign up role option rivenditore": "Kereskedő",
		"Sign up role option privato": "Magánszemély",
		"Sign up address label": "Cím*",
		"Sign up address placeholder": "Írja be címét",
		"Sign up province label": "Megye*",
		"Sign up province placeholder": "Írja be megyéjét",
		"Sign up city label": "Város*",
		"Sign up city placeholder": "Írja be városát",
		"Sign up nation label": "Ország*",
		"Sign up nation placeholder": "Írja be országát",
		"Sign up privacy":
			"Elolvastam az <a href='https://www.iubenda.com/privacy-policy/42441913' target='_blank'>adatvédelmi tájékoztatót</a>, és szeretnék értesítéseket kapni a Cosmogas-tól",
		"Sign up submit": "Regisztráció igénylése",
		"Already registered": "Már regisztrált?",
		"Sign in": "Bejelentkezés",
		"Sign up success title": "Kérés elküldve!",
		"Sign up success description":
			"Kérését sikeresen elküldtük. Várja meg, amíg fiókját jóváhagyják. <br><br>E-mailben értesítjük, amikor be tud jelentkezni",
		"Sign up success button label": "Fedezze fel a szakemberek előnyeit",
		"Sign up success link":
			"https://www.cosmogas.com/en/benefits-for-professionals/",
		"Account activation alert": "Figyelem, ne töltse újra az oldalt",
		"Account activation temp password title": "Adja meg az ideiglenes jelszót",
		"Account activation temp password description":
			"Adja meg az ideiglenes jelszót, amelyet megkapott ",
		"Account activation temp password label": "Ideiglenes jelszó",
		"Account activation temp password placeholder":
			"Adja meg az ideiglenes jelszót",
		"Account activation password title": "Új jelszó létrehozása",
		"Account activation password description":
			"Már majdnem kész van! A fiókja teljes aktiválásához hozzon létre egy új jelszót. Ezt a jelszót fogja használni a portálhoz való jövőbeli hozzáféréshez",
		"Account activation password newPassword label": "Jelszó",
		"Account activation password newPassword placeholder":
			"Adja meg új jelszavát",
		"Account activation password repeatPassword label": "Jelszó ismétlése",
		"Account activation password repeatPassword placeholder":
			"Írja be újra a jelszót",
		"Account activation password list minimum characters":
			"Legalább 8 karakter",
		"Account activation password list divider":
			"Tartalmazzon legalább három karaktertípus közül:",
		"Account activation password list uppercase": "Nagybetűk",
		"Account activation password list lowercase": "Kisbetűk",
		"Account activation password list numbers": "Számok",
		"Account activation password list characters":
			"Nem alfanumerikus karakterek",
		"Account activation password submit": "Fiók aktiválása",
		// ACCOUNT ACTIVATION SUCCESS
		"Account activation success title": "A fiók sikeresen aktiválva",
		"Account activation success description":
			"Üdvözöljük! Cosmogas-fiókját sikeresen aktiváltuk.",
		"Account activation success description full":
			"Üdvözöljük! Cosmogas-fiókját sikeresen aktiváltuk. Mostantól a létrehozott hitelesítő adatok segítségével érheti el ezeket a szolgáltatásokat:",
		"Account activation success portale label": "Portálba My Cosmogas",
		"Account activation success portale link": "https://my.cosmogas.com",
		"Account activation success website label": "Weboldal Cosmogas",
		"Account activation success website link": "https://www.cosmogas.com/hu/",
		"Account activation success cosmoclub label": "COSMOclub",
		"Account activation success cosmoclub link":
			"https://cosmoclub.cosmogas.com",
		"Account activation success acquacalda label": "Acquacalda.tech",
		"Account activation success acquacalda link":
			"https://www.acquacalda.tech/",
		"Account activation success ricambi label": "Cosmogas Ricambi",
		"Account activation success ricambi link": "https://cosmogas.ricambio.net/",
	},
	ro: {
		// PROFILE
		"Profile hello": "Bun venit",
		"Profile redirecting": "Se duce ...",
		"Profile description":
			"Te afli în contul tău Cosmogas. <br>Faceți clic pe unul dintre butoanele de mai jos pentru a accesa portalurile noastre:",
		// SIGN IN
		"Sign in title": "Conectează-te la contul tău",
		"Sign in email label": "Email",
		"Sign in email placeholder": "Introduceți emailul dvs.",
		"Sign in foreward": "Înainte",
		"Sign in submit": "Conectare",
		"Sign in not registered": "Nu ești înregistrat?",
		"Sign in request registration": "Solicită înregistrare",
		"Sign in password label": "Parolă",
		"Sign in password placeholder": "Introduceți parola dvs.",
		"Sign in forgot password": "Ați uitat parola?",
		"Forgot password title": "Parolă uitată?",
		"Forgot password description": "Introduceți emailul contului dvs. Cosmogas",
		"Forgot password email label": "Email",
		"Forgot password email placeholder": "Introduceți emailul contului dvs.",
		"Forgot password submit": "Resetați parola",
		"Confirm reset password code title": "Verificați-vă emailul",
		"Confirm reset password code description":
			"Introduceți codul de verificare trimis la ",
		"Confirm reset password code resend": "Trimiteți un cod nou",
		"Confirm reset password next step":
			"După ce introduceți codul, completați câmpurile de mai jos pentru a crea o nouă parolă",
		"Confirm reset password password title": "Creează parolă",
		"Confirm reset password password description": "Creați noua dvs. parolă",
		"Confirm reset password password newPassword label": "Parolă",
		"Confirm reset password password newPassword placeholder":
			"Introduceți noua dvs. parolă",
		"Confirm reset password password repeatPassword label": "Repetați parola",
		"Confirm reset password password repeatPassword placeholder":
			"Introduceți din nou parola",
		"Confirm reset password password list minimum characters":
			"Minim 8 caractere",
		"Confirm reset password password list password match":
			"Parolele trebuie să se potrtivească",
		"Confirm reset password password list divider":
			"Includeți cel puțin trei dintre următoarele tipuri de caractere:",
		"Confirm reset password password list uppercase": "Majuscule",
		"Confirm reset password password list lowercase": "Minuscule",
		"Confirm reset password password list numbers": "Numere",
		"Confirm reset password password list characters":
			"Caractere non-alfanumerice",
		"Confirm reset password password submit": "Resetați parola",
		"Reset password success title": "Parolă schimbată!",
		"Reset password success description":
			"Parola dvs. a fost resetată cu succes. Faceți clic mai jos pentru a vă conecta",
		"Reset password success button label": "Continuă",
		"Back to login": "Înapoi la conectare",
		Redirecting: "Vă redirecționăm către: ",
		"Footer text":
			"© Cosmogas SRL | CUI 00810400408 | Companie cu un singur asociat supusă conducerii și coordonării Clada Srl",
		"Footer privacy link": "https://www.cosmogas.com/en/privacy-policy",
		"Footer cookie link": "https://www.cosmogas.com/en/cookie-policy",
		"UserMigration failed with error User not found.":
			"Utilizatorul nu a fost găsit.",
		"Incorrect username or password.":
			"Nume de utilizator sau parolă incorectă.",
		"Password attempts exceeded":
			"Numărul de încercări pentru parolă a fost depășit.",
		"username is required to resetPassword":
			"Numele de utilizator este necesar pentru a reseta parola.",
		"Your passwords must match": "Parolele trebuie să coincidă.",
		"Invalid verification code provided, please try again.":
			"Cod de verificare invalid, vă rugăm să încercați din nou.",
		"Password must have at least 8 characters":
			"Parola trebuie să aibă cel puțin 8 caractere.",
		"Attempt limit exceeded, please try after some time.":
			"Limita de încercări a fost depășită, încercați din nou mai târziu.",
		"Password does not meet requirements": "Parola nu îndeplinește cerințele.",
		"confirmationCode is required to confirmResetPassword":
			"Este necesar un cod de verificare pentru a confirma resetarea parolei.",
		"User does not exist.": "Utilizatorul nu există.",
		"Temporary password not correct.":
			"Eroare în timpul fazei de activare a contului. Faceți clic pe „Return to login” și reintroduceți adresa de e-mail și parola temporară pe care ați primit-o. ",
		"Temporary password required.": "Introduceți parola temporară.",
		"Something went wrong. Try later.":
			"A apărut o problemă. Încercați mai târziu.",
		"Username/client id combination not found.":
			"Utilizatorul nu a fost găsit.",
		"Compila il campo richiesto.": "Completați câmpurile necesare.",
		"Cannot reset password for the user as there is no registered/verified email or phone_number":
			"Nu poate fi resetat parola pentru acest utilizator, deoarece nu există email sau numar de telefon verificat. ",
		"Sign up title": "Solicitați înregistrare",
		"Sign up description":
			"Completați câmpurile de mai jos pentru a solicita contul dvs. Cosmogas",
		"Sign up email label": "Email*",
		"Sign up email placeholder": "Introduceți emailul dvs.",
		"Sign up first name label": "Prenume*",
		"Sign up first name placeholder": "Introduceți prenumele dvs.",
		"Sign up last name label": "Nume*",
		"Sign up last name placeholder": "Introduceți numele dvs.",
		"Sign up phone label": "Telefon*",
		"Sign up phone placeholder": "Introduceți numărul dvs. de telefon",
		"Sign up partitaIva label": "CUI*",
		"Sign up partitaIva placeholder": "Introduceți CUI-ul dvs.",
		"Sign up ragioneSociale label": "Denumire firmă*",
		"Sign up ragioneSociale placeholder": "Denumire firmă",
		"Sign up role label": "Rol*",
		"Sign up role placeholder": "Introduceți rolul dvs.",
		"Sign up role option centro-assistenza": "Centru de asistență",
		"Sign up role option installatore": "Instalator",
		"Sign up role option progettista": "Proiectant",
		"Sign up role option rivenditore": "Distribuitor",
		"Sign up role option privato": "Privat",
		"Sign up address label": "Adresă*",
		"Sign up address placeholder": "Introduceți adresa dvs.",
		"Sign up province label": "Județ*",
		"Sign up province placeholder": "Introduceți județul dvs.",
		"Sign up city label": "Oraș*",
		"Sign up city placeholder": "Introduceți orașul dvs.",
		"Sign up nation label": "Țară*",
		"Sign up nation placeholder": "Introduceți țara dvs.",
		"Sign up privacy":
			"Am citit politica de confidențialitate <a href='https://www.iubenda.com/privacy-policy/42441913' target='_blank'>privacy</a> și doresc să primesc comunicări de la Cosmogas",
		"Sign up submit": "Solicitați înregistrare",
		"Already registered": "Ești deja înregistrat?",
		"Sign in": "Conectare",
		"Sign up success title": "Cerere trimisă!",
		"Sign up success description":
			"Cererea dvs. a fost trimisă cu succes. Așteptați confirmarea contului dvs. <br><br>Veți primi un email când veți putea să vă conectați",
		"Sign up success button label":
			"Descoperiți avantajele pentru profesioniști",
		"Sign up success link":
			"https://www.cosmogas.com/en/benefits-for-professionals/",
		"Account activation alert": "Atenție, nu reîncărcați pagina",
		"Account activation temp password title": "Introduceți parola temporară",
		"Account activation temp password description":
			"Introduceți parola temporară pe care ați primit-o la ",
		"Account activation temp password label": "Parolă temporară",
		"Account activation temp password placeholder":
			"Introduceți parola temporară",
		"Account activation password title": "Creați o nouă parolă",
		"Account activation password description":
			"Ați aproape terminat! Pentru a vă activa complet contul, creați o nouă parolă. Aceasta este parola pe care o veți folosi pentru a accesa portalul în viitor",
		"Account activation password newPassword label": "Parolă",
		"Account activation password newPassword placeholder":
			"Introduceți noua dvs. parolă",
		"Account activation password repeatPassword label": "Repetați parola",
		"Account activation password repeatPassword placeholder":
			"Introduceți din nou parola",
		"Account activation password list minimum characters": "Minim 8 caractere",
		"Account activation password list divider":
			"Includeți cel puțin trei dintre următoarele tipuri de caractere:",
		"Account activation password list uppercase": "Majuscule",
		"Account activation password list lowercase": "Minuscule",
		"Account activation password list numbers": "Numere",
		"Account activation password list characters": "Caractere non-alfanumerice",
		"Account activation password submit": "Activați contul",
		// ACCOUNT ACTIVATION SUCCESS
		"Account activation success title": "Contul a fost activat cu succes",
		"Account activation success description":
			"Bun venit! Contul dvs. Cosmogas a fost activat cu succes.",
		"Account activation success description full":
			"Bun venit! Contul dvs. Cosmogas a fost activat cu succes. De acum încolo puteți folosi acreditările create pentru a accesa aceste servicii:",
		"Account activation success portale label": "Portal My Cosmogas",
		"Account activation success portale link": "https://my.cosmogas.com",
		"Account activation success website label": "Site-ul web Cosmogas",
		"Account activation success website link": "https://www.cosmogas.com/ro/",
		"Account activation success cosmoclub label": "COSMOclub",
		"Account activation success cosmoclub link":
			"https://cosmoclub.cosmogas.com",
		"Account activation success acquacalda label": "Acquacalda.tech",
		"Account activation success acquacalda link":
			"https://www.acquacalda.tech/",
		"Account activation success ricambi label": "Cosmogas Ricambi",
		"Account activation success ricambi link": "https://cosmogas.ricambio.net/",
	},
	es: {
		// PROFILE
		"Profile hello": "¡Hola",
		"Profile redirecting": "Redirigiendo...",
		"Profile description":
			"Estás dentro de tu cuenta Cosmogas. <br>Haga clic en uno de los botones a continuación para acceder a nuestros portales:",
		// SIGN IN
		"Sign in title": "Inicia sesión en tu cuenta",
		"Sign in email label": "Correo electrónico",
		"Sign in email placeholder": "Introduce tu correo electrónico",
		"Sign in foreward": "Siguiente",
		"Sign in submit": "Iniciar sesión",
		"Sign in not registered": "¿No estás registrado?",
		"Sign in request registration": "Solicitar registro",
		"Sign in password label": "Contraseña",
		"Sign in password placeholder": "Introduce tu contraseña",
		"Sign in forgot password": "¿Olvidaste tu contraseña?",
		"Forgot password title": "¿Olvidaste tu contraseña?",
		"Forgot password description": "Introduce el correo de tu cuenta Cosmogas",
		"Forgot password email label": "Correo electrónico",
		"Forgot password email placeholder": "Introduce el correo de tu cuenta",
		"Forgot password submit": "Restablecer contraseña",
		"Confirm reset password code title": "Revisa tu correo",
		"Confirm reset password code description":
			"Introduce el código de verificación enviado a ",
		"Confirm reset password code resend": "Enviar un nuevo código",
		"Confirm reset password next step":
			"Después de introducir el código, completa los campos a continuación para crear tu nueva contraseña",
		"Confirm reset password password title": "Crear contraseña",
		"Confirm reset password password description": "Crea tu nueva contraseña",
		"Confirm reset password password newPassword label": "Contraseña",
		"Confirm reset password password newPassword placeholder":
			"Introduce tu nueva contraseña",
		"Confirm reset password password repeatPassword label":
			"Repite la contraseña",
		"Confirm reset password password repeatPassword placeholder":
			"Vuelve a introducir la contraseña",
		"Confirm reset password password list minimum characters":
			"Mínimo 8 caracteres",
		"Confirm reset password password list password match":
			"Las contraseñas deben coincidir",
		"Confirm reset password password list divider":
			"Incluye al menos tres de los siguientes tipos de caracteres:",
		"Confirm reset password password list uppercase": "Mayúsculas",
		"Confirm reset password password list lowercase": "Minúsculas",
		"Confirm reset password password list numbers": "Números",
		"Confirm reset password password list characters":
			"Caracteres no alfanuméricos",
		"Confirm reset password password submit": "Restablecer contraseña",
		"Reset password success title": "¡Contraseña cambiada!",
		"Reset password success description":
			"Tu contraseña se ha restablecido con éxito. Haz clic a continuación para iniciar sesión",
		"Reset password success button label": "Continuar",
		"Back to login": "Volver al inicio de sesión",
		Redirecting: "Te estamos redirigiendo a: ",
		"Footer text":
			"© Cosmogas SRL | IVA 00810400408 | Empresa de socio único sujeta a la dirección y coordinación de Clada Srl",
		"Footer privacy link": "https://www.cosmogas.com/es/privacy-policy",
		"Footer cookie link": "https://www.cosmogas.com/es/cookie-policy",
		"UserMigration failed with error User not found.": "Usuario no encontrado.",
		"Incorrect username or password.":
			"Nombre de usuario o contraseña incorrectos.",
		"Password attempts exceeded": "Intentos de contraseña superados.",
		"username is required to resetPassword":
			"Se requiere nombre de usuario para restablecer la contraseña.",
		"Your passwords must match": "Las contraseñas deben coincidir.",
		"Invalid verification code provided, please try again.":
			"Código de verificación inválido, por favor inténtalo de nuevo.",
		"Password must have at least 8 characters":
			"La contraseña debe tener al menos 8 caracteres.",
		"Attempt limit exceeded, please try after some time.":
			"Límite de intentos excedido, inténtalo más tarde.",
		"Password does not meet requirements":
			"La contraseña no cumple los requisitos.",
		"confirmationCode is required to confirmResetPassword":
			"Se requiere un código de verificación para confirmar el restablecimiento de la contraseña.",
		"User does not exist.": "El usuario no existe.",
		"Temporary password not correct.":
			'Error durante la fase de activación de la cuenta. Haga clic en "Volver a iniciar sesión" y vuelva a ingresar su correo electrónico y la contraseña temporal que recibió. ',
		"Temporary password required.": "Introduce la contraseña temporal.",
		"Something went wrong. Try later.": "Algo salió mal. Inténtalo más tarde.",
		"Username/client id combination not found.": "Usuario no encontrado.",
		"Compila il campo richiesto.": "Completa los campos requeridos.",
		"Cannot reset password for the user as there is no registered/verified email or phone_number":
			"La contraseña no se puede restablecer porque no hay correo electrónico o número de telémóvil verificado. ",
		"Sign up title": "Solicitar registro",
		"Sign up description":
			"Completa los campos a continuación para solicitar tu cuenta Cosmogas",
		"Sign up email label": "Correo electrónico*",
		"Sign up email placeholder": "Introduce tu correo electrónico",
		"Sign up first name label": "Nombre*",
		"Sign up first name placeholder": "Introduce tu nombre",
		"Sign up last name label": "Apellido*",
		"Sign up last name placeholder": "Introduce tu apellido",
		"Sign up phone label": "Teléfono*",
		"Sign up phone placeholder": "Introduce tu número de teléfono",
		"Sign up partitaIva label": "NIF*",
		"Sign up partitaIva placeholder": "Introduce tu NIF",
		"Sign up ragioneSociale label": "Razón social*",
		"Sign up ragioneSociale placeholder": "Razón social",
		"Sign up role label": "Rol*",
		"Sign up role placeholder": "Introduce tu rol",
		"Sign up role option centro-assistenza": "Centro de asistencia",
		"Sign up role option installatore": "Instalador",
		"Sign up role option progettista": "Diseñador",
		"Sign up role option rivenditore": "Distribuidor",
		"Sign up role option privato": "Particular",
		"Sign up address label": "Dirección*",
		"Sign up address placeholder": "Introduce tu dirección",
		"Sign up province label": "Provincia*",
		"Sign up province placeholder": "Introduce tu provincia",
		"Sign up city label": "Ciudad*",
		"Sign up city placeholder": "Introduce tu ciudad",
		"Sign up nation label": "País*",
		"Sign up nation placeholder": "Introduce tu país",
		"Sign up privacy":
			"He leído la política de <a href='https://www.iubenda.com/privacy-policy/42441913' target='_blank'>privacidad</a> y deseo recibir comunicaciones de Cosmogas",
		"Sign up submit": "Solicitar registro",
		"Already registered": "¿Ya estás registrado?",
		"Sign in": "Iniciar sesión",
		"Sign up success title": "¡Solicitud enviada!",
		"Sign up success description":
			"Tu solicitud ha sido enviada con éxito. Espera la confirmación de tu cuenta. <br><br>Recibirás un correo electrónico cuando puedas iniciar sesión",
		"Sign up success button label":
			"Descubre los beneficios para profesionales",
		"Sign up success link":
			"https://www.cosmogas.com/es/ventajas-para-los-profesionales/",
		"Account activation alert": "Advertencia, no recargues la página",
		"Account activation temp password title":
			"Introduce la contraseña temporal",
		"Account activation temp password description":
			"Introduce la contraseña temporal que has recibido en ",
		"Account activation temp password label": "Contraseña temporal",
		"Account activation temp password placeholder":
			"Introduce la contraseña temporal",
		"Account activation password title": "Crear nueva contraseña",
		"Account activation password description":
			"¡Ya casi terminas! Para activar completamente tu cuenta, crea una nueva contraseña. Esta será la contraseña que usarás para acceder al Portal en el futuro",
		"Account activation password newPassword label": "Contraseña",
		"Account activation password newPassword placeholder":
			"Introduce tu nueva contraseña",
		"Account activation password repeatPassword label": "Repite la contraseña",
		"Account activation password repeatPassword placeholder":
			"Vuelve a introducir la contraseña",
		"Account activation password list minimum characters":
			"Mínimo 8 caracteres",
		"Account activation password list divider":
			"Incluye al menos tres de los siguientes tipos de caracteres:",
		"Account activation password list uppercase": "Mayúsculas",
		"Account activation password list lowercase": "Minúsculas",
		"Account activation password list numbers": "Números",
		"Account activation password list characters":
			"Caracteres no alfanuméricos",
		"Account activation password submit": "Activar cuenta",
		// ACCOUNT ACTIVATION SUCCESS
		"Account activation success title": "Cuenta activada exitosamente",
		"Account activation success description":
			"¡Bienvenido! Su cuenta Cosmogas ha sido activada exitosamente.",
		"Account activation success description full":
			"¡Bienvenido! Su cuenta Cosmogas ha sido activada exitosamente. A partir de ahora podrás utilizar las credenciales creadas para acceder a estos servicios:",
		"Account activation success portale label": "Portal My Cosmogas",
		"Account activation success portale link": "https://my.cosmogas.com",
		"Account activation success website label": "Sitio web Cosmogas",
		"Account activation success website link": "https://www.cosmogas.com/es/",
		"Account activation success cosmoclub label": "COSMOclub",
		"Account activation success cosmoclub link":
			"https://cosmoclub.cosmogas.com",
		"Account activation success acquacalda label": "Acquacalda.tech",
		"Account activation success acquacalda link":
			"https://www.acquacalda.tech/",
		"Account activation success ricambi label": "Cosmogas Ricambi",
		"Account activation success ricambi link": "https://cosmogas.ricambio.net/",
	},
};
